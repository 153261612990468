// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_cy-0";
export var footer = "_cy-2";
export var infoSection = "_cy-3";
export var infoSectionContainer = "_cy-4";
export var infoSectionTitleRow = "_cy-5";
export var itemDetails = "_cy-6";
export var line = "_cy-7";
export var lineItemImage = "_cy-1";
export var lineItems = "_cy-8";
export var modalContent = "_cy-9";
export var multiLineInfo = "_cy-a";
export var orderName = "_cy-b";
export var strong = "_cy-c";