// extracted by mini-css-extract-plugin
export var colorSquare = "_d5-0";
export var danger = "_d5-5";
export var lightPink = "_d5-8";
export var neutralBlue = "_d5-7";
export var neutralYellow = "_d5-6";
export var normal = "_d5-1";
export var primary = "_d5-2";
export var primaryLight = "_d5-3";
export var solidBlack = "_d5-g";
export var solidGreen = "_d5-b";
export var solidLightBlue = "_d5-d";
export var solidNavy = "_d5-c";
export var solidOrange = "_d5-f";
export var solidPink = "_d5-e";
export var solidRed = "_d5-9";
export var solidYellow = "_d5-a";
export var success = "_d5-4";