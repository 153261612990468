// extracted by mini-css-extract-plugin
export var actionButton = "_92-t";
export var buttonContainer = "_92-0";
export var buttonContainer2 = "_92-1";
export var buttonSection = "_92-2";
export var details = "_92-3";
export var dropdownItem = "_92-5";
export var dropdownItemsContainer = "_92-4";
export var fileInput = "_92-r";
export var headerButton = "_92-6";
export var icon = "_92-7";
export var input = "_92-8";
export var macroListItem = "_92-9";
export var mainContent = "_92-a";
export var microText = "_92-b";
export var modal = "_92-w";
export var optionsButton = "_92-c";
export var paperclip = "_92-s";
export var pillContent = "_92-d";
export var preview = "_92-e";
export var preview2 = "_92-f";
export var quillEditor = "_92-q";
export var selectedMacro = "_92-g";
export var sidebar = "_92-h";
export var sidebarHeader = "_92-j";
export var sidebarList = "_92-i";
export var sidebarListItem = "_92-k";
export var sidebarSearch = "_92-l";
export var snoozeDurationWrapper = "_92-v";
export var statusPillContainer = "_92-m";
export var tagsContainer = "_92-n";
export var unselectedMacro = "_92-o";
export var variable = "_92-u";
export var wrapper = "_92-p";