// extracted by mini-css-extract-plugin
export var addButton = "_8h-0";
export var border = "_8h-8";
export var check = "_8h-h";
export var danger = "_8h-6";
export var dropdownContainer = "_8h-1";
export var editModalBody = "_8h-j";
export var footerContainer = "_8h-i";
export var fullWidth = "_8h-a";
export var hide = "_8h-9";
export var icon = "_8h-3";
export var input = "_8h-7";
export var inputFlex = "_8h-b";
export var inputWrapper = "_8h-c";
export var manualFocus = "_8h-5";
export var optionButton = "_8h-4";
export var pillWrapper = "_8h-e";
export var pointer = "_8h-2";
export var showOverflow = "_8h-f";
export var tagsFlex = "_8h-d";
export var xButton = "_8h-g";