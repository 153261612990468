// extracted by mini-css-extract-plugin
export var box = "_48-1";
export var checked = "_48-5";
export var descriptionSpacer = "_48-9";
export var disabled = "_48-4";
export var icon = "_48-6";
export var input = "_48-0";
export var labelSpacer = "_48-8";
export var medium = "_48-3";
export var small = "_48-2";
export var text = "_48-7";