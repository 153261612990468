// extracted by mini-css-extract-plugin
export var aboveInput = "_9m-0";
export var actionButton = "_9m-5";
export var actionButtonContainer = "_9m-1";
export var actionIcon = "_9m-6";
export var aiInfoContainer = "_9m-2";
export var attachmentWrapper = "_9m-7";
export var bold = "_9m-3";
export var checkIcon = "_9m-8";
export var clickingRecipientBar = "_9m-11";
export var commentThreadSwitch = "_9m-a";
export var draftImage = "_9m-b";
export var draftImageContainer = "_9m-c";
export var draftImages = "_9m-e";
export var editButton = "_9m-g";
export var editor = "_9m-f";
export var editorContainer = "_9m-y";
export var editorErrorTooltipWrapper = "_9m-9";
export var headerDivider = "_9m-h";
export var icon = "_9m-i";
export var info = "_9m-j";
export var internal = "_9m-n";
export var internalDivider = "_9m-k";
export var internalMessage = "_9m-l";
export var messageInputCard = "_9m-m";
export var messageInputForm = "_9m-o";
export var messageInputFormInternal = "_9m-p";
export var options = "_9m-q";
export var publicMessageCustomer = "_9m-s";
export var publicMessageMerchant = "_9m-r";
export var removeImageButton = "_9m-d";
export var replyButtons = "_9m-t";
export var showSignatureButton = "_9m-u";
export var spinner = "_9m-v";
export var verticalButtonDivider = "_9m-4";
export var visibilitySelector = "_9m-w";
export var warning = "_9m-x";
export var wrappingText = "_9m-z";
export var writingInternalNoteTopBorder = "_9m-10";