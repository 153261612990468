// extracted by mini-css-extract-plugin
export var checkbox = "_3z-0";
export var circle = "_3z-3";
export var horizontalContainer = "_3z-7";
export var sm = "_3z-5";
export var switch0 = "_3z-2";
export var switchDisabled = "_3z-8";
export var switchEnabled = "_3z-1";
export var textSwitchContainer = "_3z-9";
export var textSwitchOption = "_3z-a";
export var textSwitchOptionSelected = "_3z-b";
export var verticalContainer = "_3z-6";
export var xs = "_3z-4";