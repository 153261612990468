// extracted by mini-css-extract-plugin
export var actionButton = "_8g-3e";
export var actionButtonText = "_8g-3d";
export var addIcon = "_8g-13";
export var addItem = "_8g-1f";
export var addNewItemButton = "_8g-8";
export var addNewItemButtonText = "_8g-9";
export var addNewItemIcon = "_8g-a";
export var addressSpace = "_8g-2s";
export var adjustmentBonus = "_8g-1i";
export var adjustmentFee = "_8g-1j";
export var buttonBar = "_8g-2v";
export var buttonContentNew = "_8g-2t";
export var buttonDropdown = "_8g-2x";
export var buttonDropdown2 = "_8g-2y";
export var buttonDropdownContainer = "_8g-2w";
export var card = "_8g-0";
export var changeAddress = "_8g-39";
export var chevron = "_8g-i";
export var clickAwayListener = "_8g-1u";
export var clickable = "_8g-g";
export var container = "_8g-2";
export var coverage = "_8g-25";
export var createLabelsImage = "_8g-2j";
export var deleteIcon = "_8g-2p";
export var deleteIconContainer = "_8g-2o";
export var dropdownContainer = "_8g-3n";
export var dropdownIcon = "_8g-3p";
export var dropdownIconOpen = "_8g-3q";
export var dropdownItem = "_8g-3r";
export var dropdownOption = "_8g-2z";
export var dropdownOptionText = "_8g-2u";
export var dropdownOverlay = "_8g-3t";
export var dropdownText = "_8g-3s";
export var dropdownTrigger = "_8g-3o";
export var editIcon = "_8g-f";
export var editNewItemsLink = "_8g-12";
export var emptyExchangeOrder = "_8g-1m";
export var error = "_8g-h";
export var expandButton = "_8g-3g";
export var expandButtonContainer = "_8g-3h";
export var expanded = "_8g-3i";
export var external = "_8g-24";
export var fraudRiskContainer = "_8g-2f";
export var fullHeight = "_8g-2i";
export var fullWidth = "_8g-2h";
export var greenReturnAdjustment = "_8g-1n";
export var greenReturnImage = "_8g-1r";
export var greenReturnSelectContainer = "_8g-1o";
export var greenReturnSelectTitle = "_8g-1p";
export var greenReturnText = "_8g-1q";
export var headerLayout = "_8g-30";
export var headerSubtitleSmall = "_8g-38";
export var hiddenValues = "_8g-3j";
export var hiddenValuesClosing = "_8g-3l";
export var icon = "_8g-3";
export var item = "_8g-k";
export var itemCard = "_8g-2k";
export var itemCommentImage = "_8g-6";
export var itemCommentImages = "_8g-7";
export var itemComments = "_8g-5";
export var itemHeader = "_8g-q";
export var itemImage = "_8g-l";
export var itemLink = "_8g-21";
export var itemMain = "_8g-m";
export var itemOption = "_8g-v";
export var itemOptions = "_8g-s";
export var itemOptionsText = "_8g-t";
export var itemPrice = "_8g-y";
export var itemPriceContainer = "_8g-16";
export var itemPriceEdit = "_8g-x";
export var itemPriceText = "_8g-17";
export var itemProperties = "_8g-n";
export var itemProperty = "_8g-o";
export var itemPropertyName = "_8g-p";
export var itemSearch = "_8g-1g";
export var itemSubtitle = "_8g-z";
export var itemSubtitleText = "_8g-10";
export var itemSummary = "_8g-18";
export var itemSummaryLine = "_8g-19";
export var itemSummaryValue = "_8g-1a";
export var itemTitle = "_8g-r";
export var itemTitleIcon = "_8g-u";
export var itemType = "_8g-w";
export var items = "_8g-4";
export var linkColor = "_8g-3a";
export var menuTitle = "_8g-1c";
export var modalButton = "_8g-1s";
export var modalContent = "_8g-26";
export var modalFooterRight = "_8g-1t";
export var newAddress = "_8g-j";
export var newItemButtonText = "_8g-e";
export var newItemHeader = "_8g-1k";
export var newItemHeaderTabs = "_8g-1l";
export var newItemShipping = "_8g-b";
export var newItems = "_8g-2r";
export var newItemsSection = "_8g-2q";
export var noTextWrap = "_8g-2l";
export var nonPriceContainer = "_8g-11";
export var optionContent = "_8g-1d";
export var optionTitle = "_8g-1b";
export var orderAndTags = "_8g-3v";
export var orderLink = "_8g-20";
export var orderNames = "_8g-3w";
export var orderNumber = "_8g-3f";
export var orderNumbers = "_8g-32";
export var originalPrice = "_8g-14";
export var paddedModalContent = "_8g-27";
export var priceSummaryTitle = "_8g-1e";
export var processButtons = "_8g-28";
export var product = "_8g-22";
export var productTitle = "_8g-3u";
export var question = "_8g-3c";
export var questions = "_8g-3b";
export var returnTypeModal = "_8g-1v";
export var returnTypeModalButton = "_8g-1y";
export var returnTypeModalHeader = "_8g-1x";
export var right = "_8g-15";
export var rightAligned = "_8g-1w";
export var riskReportIframe = "_8g-2g";
export var shippingText = "_8g-c";
export var shippingTextHeader = "_8g-d";
export var slideDown = "_8g-3k";
export var slideUp = "_8g-3m";
export var status = "_8g-23";
export var subsection = "_8g-2d";
export var subtitle = "_8g-36";
export var subtitleText = "_8g-37";
export var summaryElement = "_8g-1";
export var table = "_8g-1z";
export var tableCell = "_8g-2c";
export var tableDescription = "_8g-2b";
export var tableHeader = "_8g-2a";
export var tableHeaderGroup = "_8g-29";
export var tags = "_8g-34";
export var titleText = "_8g-35";
export var tooltipLink = "_8g-2n";
export var top = "_8g-33";
export var topRow = "_8g-31";
export var trackingNumbers = "_8g-2e";
export var trash = "_8g-2m";
export var voided = "_8g-1h";