// extracted by mini-css-extract-plugin
export var disabled = "_b5-6";
export var error = "_b5-7";
export var icon = "_b5-0";
export var inherit = "_b5-a";
export var primary = "_b5-1";
export var quaternary = "_b5-4";
export var quinary = "_b5-5";
export var secondary = "_b5-2";
export var success = "_b5-9";
export var tertiary = "_b5-3";
export var warning = "_b5-8";