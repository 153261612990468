// extracted by mini-css-extract-plugin
export var arrows = "_4h-5";
export var decrementer = "_4h-9";
export var disabled = "_4h-3";
export var error = "_4h-1";
export var hiddenText = "_4h-a";
export var incrementer = "_4h-8";
export var input = "_4h-7";
export var inputWrapper = "_4h-0";
export var readonly = "_4h-2";
export var regular = "_4h-6";
export var small = "_4h-4";