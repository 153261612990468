// extracted by mini-css-extract-plugin
export var actionButton = "_99-1";
export var actions = "_99-0";
export var copyableField = "_99-2";
export var detailColumn = "_99-3";
export var detailRow = "_99-4";
export var dropdownTitle = "_99-5";
export var fulfillmentDetails = "_99-7";
export var fulfillments = "_99-6";
export var icon = "_99-8";
export var imgContainer = "_99-9";
export var lineItems = "_99-a";
export var link = "_99-p";
export var orderCard = "_99-k";
export var orderCollapseButton = "_99-l";
export var orderCollapseButtonIcon = "_99-m";
export var orderDetails = "_99-b";
export var orderPillsContainer = "_99-c";
export var pillWrapper = "_99-d";
export var product = "_99-e";
export var productDetails = "_99-g";
export var productTopDetails = "_99-h";
export var products = "_99-f";
export var strong = "_99-i";
export var tightText = "_99-o";
export var tooltipHidden = "_99-q";
export var variantQuantityRow = "_99-j";
export var wrappingText = "_99-n";