// extracted by mini-css-extract-plugin
export var copyableField = "_by-4";
export var customerBadge = "_by-0";
export var customerInfoTitle = "_by-2";
export var details = "_by-1";
export var dropdownContainer = "_by-5";
export var dropdownImage = "_by-6";
export var editAssigneeForm = "_by-a";
export var editButton = "_by-3";
export var email = "_by-7";
export var hidden = "_by-d";
export var input = "_by-8";
export var saveButton = "_by-b";
export var shopifyIcon = "_by-c";
export var username = "_by-9";