// extracted by mini-css-extract-plugin
export var danger = "_4j-6";
export var lightPink = "_4j-a";
export var medium = "_4j-j";
export var nearSquare = "_4j-m";
export var neutralBlue = "_4j-8";
export var neutralYellow = "_4j-7";
export var noCapitalize = "_4j-1";
export var normal = "_4j-2";
export var pill = "_4j-0";
export var primary = "_4j-3";
export var primaryLight = "_4j-4";
export var shadow = "_4j-n";
export var small = "_4j-k";
export var solidBlack = "_4j-i";
export var solidGreen = "_4j-d";
export var solidLightBlue = "_4j-f";
export var solidNavy = "_4j-e";
export var solidOrange = "_4j-h";
export var solidPink = "_4j-g";
export var solidRed = "_4j-b";
export var solidWhite = "_4j-9";
export var solidYellow = "_4j-c";
export var success = "_4j-5";
export var xsmall = "_4j-l";