// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_b9-f";
export var baseCustomerActivityCardArrowRightContainer = "_b9-e";
export var baseCustomerActivityCardContainer = "_b9-c";
export var baseCustomerActivityCardContainerHovered = "_b9-d";
export var conversationActivityDataConversationIconContainer = "_b9-5";
export var customerActivityDataClaimIcon = "_b9-8";
export var customerActivityDataClaimIconContainer = "_b9-7";
export var customerActivityDataConversationIcon = "_b9-6";
export var customerActivityDataConversationIconContainer = "_b9-4";
export var customerActivityDataOrderIcon = "_b9-1";
export var customerActivityDataOrderIconContainer = "_b9-0";
export var customerActivityDataReturnIcon = "_b9-3";
export var customerActivityDataReturnIconContainer = "_b9-2";
export var customerActivityShoppingIconContainer = "_b9-9";
export var messageBubble = "_b9-a";
export var messageBubbleText = "_b9-b";