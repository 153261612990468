// extracted by mini-css-extract-plugin
export var commandBarModal = "_1y-0";
export var commandIcon = "_1y-5";
export var menuItem = "_1y-8";
export var resultsSection = "_1y-7";
export var scrollContainer = "_1y-9";
export var searchIcon = "_1y-2";
export var searchInput = "_1y-3";
export var searchInputContainer = "_1y-1";
export var shortcutContainer = "_1y-4";
export var shortcutText = "_1y-6";