// extracted by mini-css-extract-plugin
export var box = "_4b-0";
export var check = "_4b-1";
export var checkboxButton = "_4b-6";
export var checkboxGroup = "_4b-5";
export var disabled = "_4b-7";
export var ghost = "_4b-9";
export var input = "_4b-2";
export var label = "_4b-4";
export var normal = "_4b-8";
export var uncheck = "_4b-3";