// extracted by mini-css-extract-plugin
export var bubbleContent = "_bq-0";
export var dark = "_bq-2";
export var dot = "_bq-7";
export var firstDot = "_bq-9";
export var left = "_bq-3";
export var light = "_bq-1";
export var loadingBubble = "_bq-5";
export var loadingDot = "_bq-c";
export var loadingDotContainer = "_bq-6";
export var moveUpDown = "_bq-8";
export var right = "_bq-4";
export var secondDot = "_bq-a";
export var thirdDot = "_bq-b";