// extracted by mini-css-extract-plugin
export var alternate = "_30-h";
export var black = "_30-3";
export var blue = "_30-d";
export var blueLight = "_30-c";
export var brand = "_30-7";
export var error = "_30-9";
export var gray = "_30-6";
export var grayBlue = "_30-b";
export var iconWrapper = "_30-4";
export var indigo = "_30-e";
export var orange = "_30-g";
export var pink = "_30-f";
export var primary = "_30-0";
export var purple = "_30-8";
export var success = "_30-5";
export var warning = "_30-a";
export var white = "_30-1";
export var whiteSuccess = "_30-2";