// extracted by mini-css-extract-plugin
export var disabled = "_2s-4";
export var error = "_2s-1";
export var iconWrapper = "_2s-8";
export var infoIconWrapper = "_2s-a _2s-8";
export var input = "_2s-7";
export var inputWrapper = "_2s-0";
export var large = "_2s-6";
export var leadingIconWrapper = "_2s-9 _2s-8";
export var measureBox = "_2s-b";
export var medium = "_2s-5";
export var readonly = "_2s-2";
export var small = "_2s-3";