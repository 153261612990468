// extracted by mini-css-extract-plugin
export var active = "_v-d";
export var blink = "_v-e";
export var container = "_v-5";
export var controls = "_v-0";
export var controlsRight = "_v-1";
export var fakeCursor = "_v-c";
export var hidden = "_v-b";
export var quillContainerSmall = "_v-7";
export var quillEditor = "_v-6";
export var quillEditorSmall = "_v-8";
export var quillFormatButtons = "_v-a";
export var quillToolbar = "_v-9";
export var removePadding = "_v-2";
export var state = "_v-3";
export var stateTitle = "_v-4";