// extracted by mini-css-extract-plugin
export var actionButton = "_bt-0";
export var bubble = "_bt-2";
export var bubbleContext = "_bt-6";
export var bubbleItems = "_bt-7";
export var customer = "_bt-3";
export var divider = "_bt-8";
export var fileList = "_bt-9";
export var infoAbove = "_bt-a";
export var internal = "_bt-4";
export var lightActionButton = "_bt-1";
export var link = "_bt-b";
export var merchant = "_bt-5";
export var message = "_bt-c";
export var profilePicture = "_bt-e";
export var quillToolbarContainer = "_bt-f";
export var senderDate = "_bt-g";
export var senderDateCustomer = "_bt-h";
export var senderName = "_bt-d";
export var time = "_bt-i";
export var uploadedImage = "_bt-j";
export var username = "_bt-k";
export var xsmall = "_bt-l";