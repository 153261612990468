// extracted by mini-css-extract-plugin
export var defaultWrapper = "_a6-3";
export var downloadButton = "_a6-4";
export var fileContainer = "_a6-7";
export var fileInfo = "_a6-5";
export var fileInfoWidthRestricted = "_a6-6";
export var fileType = "_a6-1";
export var fileTypeName = "_a6-2";
export var image = "_a6-0";
export var link = "_a6-9";
export var overflowEllipsis = "_a6-8";