// extracted by mini-css-extract-plugin
export var actionButton = "_2y-1";
export var actionButtons = "_2y-2";
export var actions = "_2y-0";
export var alignCenter = "_2y-h";
export var backWrapper = "_2y-3";
export var cellContainer = "_2y-4";
export var checkboxContainer = "_2y-5";
export var contentContainer = "_2y-6";
export var contentHeader = "_2y-8";
export var conversationsTableWrapper = "_2y-s";
export var draftReplyBadgeContainer = "_2y-m";
export var dropdownItem = "_2y-b";
export var dropdownItemsContainer = "_2y-a";
export var dropdownTitle = "_2y-c";
export var errorIcon = "_2y-7";
export var filter = "_2y-d";
export var gray = "_2y-i";
export var headerActionButtons = "_2y-e";
export var headerCheckboxContainer = "_2y-f";
export var icon = "_2y-j";
export var menuContainer = "_2y-k";
export var noMoreTicketsIcon = "_2y-11";
export var noMoreTicketsSubText = "_2y-12";
export var oneLine = "_2y-l";
export var optionsButton = "_2y-n";
export var plus = "_2y-o";
export var portalButtonsWrapper = "_2y-g";
export var red = "_2y-p";
export var scrolledTop = "_2y-9";
export var search = "_2y-q";
export var selectedConversationsCount = "_2y-r";
export var spinner = "_2y-t";
export var strong = "_2y-u";
export var summaryCell = "_2y-v";
export var tableContainer = "_2y-10";
export var tagContainer = "_2y-w";
export var threeDots = "_2y-x";
export var twoLines = "_2y-y";
export var unread = "_2y-z";