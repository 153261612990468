// extracted by mini-css-extract-plugin
export var actionButton = "_d3-1";
export var actions = "_d3-0";
export var copyableField = "_d3-2";
export var icon = "_d3-3";
export var overflowVisible = "_d3-4";
export var pillWrapper = "_d3-5";
export var productDetails = "_d3-7";
export var productPillsContainer = "_d3-8";
export var productTopDetails = "_d3-9";
export var products = "_d3-6";
export var returnDetails = "_d3-a";
export var strong = "_d3-b";
export var variantQuantityRow = "_d3-c";