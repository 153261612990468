// extracted by mini-css-extract-plugin
export var container = "_6k-0";
export var content = "_6k-3";
export var customerTitle = "_6k-4";
export var empty = "_6k-5";
export var gray = "_6k-6";
export var header = "_6k-7";
export var info = "_6k-8";
export var loading = "_6k-2";
export var loadingCard = "_6k-9";
export var oneLine = "_6k-a";
export var personName = "_6k-e";
export var pillContainer = "_6k-b";
export var selected = "_6k-1";
export var text = "_6k-c";
export var twoLines = "_6k-d";
export var unread = "_6k-f";
export var unreadIndicator = "_6k-g";