// extracted by mini-css-extract-plugin
export var buttons = "_3y-0";
export var buttonsActive = "_3y-h";
export var cancelConfirm = "_3y-1";
export var closeButton = "_3y-2";
export var column = "_3y-3";
export var container = "_3y-4";
export var detailHeader = "_3y-6";
export var detailTitle = "_3y-7";
export var detailsContent = "_3y-5";
export var header = "_3y-8";
export var headerWithClose = "_3y-9";
export var icon = "_3y-a";
export var infoIcon = "_3y-c";
export var informationContainer = "_3y-b";
export var sideDrawer = "_3y-d";
export var sideDrawerOpen = "_3y-e";
export var stickyFooter = "_3y-f";
export var typeOption = "_3y-g";