// extracted by mini-css-extract-plugin
export var avatarContainer = "_b4-0";
export var gray = "_b4-f";
export var green = "_b4-c";
export var icon = "_b4-9";
export var image = "_b4-1";
export var lg = "_b4-6";
export var md = "_b4-5";
export var presenceDot = "_b4-b";
export var red = "_b4-d";
export var sm = "_b4-4";
export var status = "_b4-a";
export var xl = "_b4-7";
export var xs = "_b4-3";
export var xxl = "_b4-8";
export var xxs = "_b4-2";
export var yellow = "_b4-e";