// extracted by mini-css-extract-plugin
export var acitonButtons = "_7o-0";
export var block = "_7o-1";
export var blockContent = "_7o-7";
export var blockDescription = "_7o-8";
export var blockIcon = "_7o-9";
export var blockTitle = "_7o-6";
export var border = "_7o-a";
export var center = "_7o-k";
export var edge = "_7o-b";
export var edgeLabel = "_7o-c";
export var edgePath = "_7o-d";
export var error = "_7o-e";
export var flowchart = "_7o-f";
export var flowchartContent = "_7o-g";
export var highlighted = "_7o-3";
export var hover = "_7o-4";
export var icon = "_7o-h";
export var left = "_7o-l";
export var navButton = "_7o-i";
export var navButtons = "_7o-j";
export var right = "_7o-m";
export var selected = "_7o-2";
export var small = "_7o-5";