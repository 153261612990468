// extracted by mini-css-extract-plugin
export var border = "_80-b";
export var circle = "_80-4";
export var circleInner = "_80-5";
export var disabled = "_80-1";
export var focused = "_80-a";
export var md = "_80-8";
export var radioButton = "_80-0";
export var radioInput = "_80-2";
export var selected = "_80-9";
export var sm = "_80-7";
export var text = "_80-3";
export var xs = "_80-6";