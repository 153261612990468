// extracted by mini-css-extract-plugin
export var animationContainer = "_d1-0";
export var footer = "_d1-1";
export var gray = "_d1-2";
export var lineItem = "_d1-3";
export var lineItemImage = "_d1-4";
export var lineItemInfo = "_d1-5";
export var lineItemText = "_d1-6";
export var lineItems = "_d1-7";
export var modalContent = "_d1-8";
export var options = "_d1-9";
export var orderName = "_d1-a";
export var refundAmount = "_d1-b";