// extracted by mini-css-extract-plugin
export var addButton = "_aw-0";
export var border = "_aw-8";
export var check = "_aw-h";
export var danger = "_aw-6";
export var dropdownContainer = "_aw-1";
export var editModalBody = "_aw-j";
export var footerContainer = "_aw-i";
export var fullWidth = "_aw-a";
export var hide = "_aw-9";
export var icon = "_aw-3";
export var input = "_aw-7";
export var inputFlex = "_aw-b";
export var inputWrapper = "_aw-c";
export var manualFocus = "_aw-5";
export var optionButton = "_aw-4";
export var pillWrapper = "_aw-e";
export var pointer = "_aw-2";
export var showOverflow = "_aw-f";
export var tagsFlex = "_aw-d";
export var xButton = "_aw-g";