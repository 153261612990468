// extracted by mini-css-extract-plugin
export var container = "_41-0";
export var error = "_41-9";
export var label = "_41-5";
export var light = "_41-6";
export var md = "_41-3";
export var sm = "_41-2";
export var thin = "_41-8";
export var thinBold = "_41-7";
export var vertical = "_41-4";
export var xs = "_41-1";