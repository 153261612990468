// extracted by mini-css-extract-plugin
export var disabled = "_5-6";
export var hierarchyLinkGray = "_5-a";
export var hierarchyLinkUnderline = "_5-9";
export var hierarchyPrimary = "_5-0";
export var hierarchySecondary = "_5-7";
export var hierarchyTertiary = "_5-8";
export var pressed = "_5-2";
export var themeAction = "_5-1";
export var themeDestructive = "_5-4";
export var themeNormal = "_5-3";
export var themeSuccess = "_5-5";