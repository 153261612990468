// extracted by mini-css-extract-plugin
export var attachment = "_bw-a";
export var emailContainer = "_bw-4";
export var emailContentPaddedContainer = "_bw-b";
export var emailHeader = "_bw-6";
export var emailInfoIcon = "_bw-9";
export var emailShortenedSummary = "_bw-e";
export var infoDropdown = "_bw-0";
export var infoRowBoundedContent = "_bw-d";
export var internal = "_bw-5";
export var merchant = "_bw-8";
export var messageInputContainer = "_bw-h";
export var normal = "_bw-7";
export var replyCard = "_bw-f";
export var shadowDomWrapper = "_bw-3";
export var subtleButton = "_bw-1";
export var subtleButtonIconContainer = "_bw-2";
export var toggleViewRepliesButton = "_bw-c";
export var wrappingText = "_bw-g";