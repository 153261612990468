// extracted by mini-css-extract-plugin
export var alignCenter = "_2w-d";
export var bannerShown = "_2w-6";
export var buttonContent = "_2w-0";
export var cardList = "_2w-1";
export var centerContent = "_2w-2";
export var chevron = "_2w-3";
export var closePanelButton = "_2w-k";
export var conversationContextContainer = "_2w-l";
export var conversationDetailContainer = "_2w-4";
export var detailWrapper = "_2w-7";
export var dropdownButton = "_2w-e";
export var dropdownTitle = "_2w-9";
export var fullHeight = "_2w-5";
export var hidden = "_2w-a";
export var icon = "_2w-b";
export var panel = "_2w-i";
export var portalButtonsWrapper = "_2w-c";
export var right = "_2w-j";
export var tableSummaryContainer = "_2w-8";
export var tableSummaryHeader = "_2w-g";
export var threeDotsButton = "_2w-f";
export var wide = "_2w-h";