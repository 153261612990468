// extracted by mini-css-extract-plugin
export var activityItem = "_8c-0";
export var avatarContainer = "_8c-5";
export var commentImage = "_8c-9";
export var deleteButton = "_8c-f";
export var dotContainer = "_8c-b";
export var externalLink = "_8c-3";
export var icon = "_8c-c";
export var input = "_8c-7";
export var inputContainer = "_8c-6";
export var leftContent = "_8c-d";
export var messageBox = "_8c-8";
export var messageInputWrapper = "_8c-a";
export var rightContent = "_8c-e";
export var timelineContainer = "_8c-1";
export var timelineItem = "_8c-2";
export var timelineLine = "_8c-4";