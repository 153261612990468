// extracted by mini-css-extract-plugin
export var createdDate = "_9l-1";
export var divider = "_9l-2";
export var headerDivider = "_9l-3";
export var messagesCard = "_9l-0";
export var messagesContainer = "_9l-7";
export var messagesContainerWrapper = "_9l-4";
export var pointerCapturingButtonBoxes = "_9l-a";
export var showLeftPanelButton = "_9l-9";
export var showMoreButton = "_9l-c";
export var showMoreButtonContainer = "_9l-b";
export var summaryTitle = "_9l-6";
export var systemMessageCardWrapper = "_9l-d";
export var topButtons = "_9l-8";
export var withPadding = "_9l-5";