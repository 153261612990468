// extracted by mini-css-extract-plugin
export var animationContainer = "_6m-0";
export var conversationContainer = "_6m-1";
export var detailsContent = "_6m-2";
export var message = "_6m-3";
export var modalContent = "_6m-4";
export var modalFooter = "_6m-6";
export var modalHeader = "_6m-5";
export var name = "_6m-7";
export var optionContainer = "_6m-8";
export var optionTitle = "_6m-9";
export var pillContainer = "_6m-a";
export var selectedConversationsContainer = "_6m-b";
export var selectedOptionContainer = "_6m-c";