// extracted by mini-css-extract-plugin
export var brand = "_50-7";
export var compact = "_50-5";
export var extraSmall = "_50-a";
export var icon = "_50-0";
export var large = "_50-3";
export var light = "_50-1";
export var medium = "_50-4";
export var outline = "_50-9";
export var primaryDark = "_50-8";
export var primaryLight = "_50-6";
export var primaryModern = "_50-b";
export var success = "_50-2";