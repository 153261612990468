// extracted by mini-css-extract-plugin
export var actions = "_21-0";
export var actionsContainer = "_21-4";
export var border = "_21-1";
export var childFullHeight = "_21-b";
export var collapse = "_21-2";
export var content = "_21-6";
export var contentChild = "_21-a";
export var fullscreen = "_21-8";
export var hidePadding = "_21-7";
export var noScrollX = "_21-c";
export var noScrollY = "_21-d";
export var optOutTopPadding = "_21-9";
export var pagePadding = "_21-5";
export var profile = "_21-e";
export var profileContainer = "_21-f";
export var profileImage = "_21-g";
export var profileMain = "_21-h";
export var profileSubtitle = "_21-j";
export var profileTitle = "_21-i";
export var tabsWrapper = "_21-3";
export var title = "_21-k";