// extracted by mini-css-extract-plugin
export var centered = "_27-6";
export var flexible = "_27-5";
export var footer = "_27-k";
export var footerBorder = "_27-l";
export var header = "_27-c";
export var headerBorder = "_27-d";
export var headerDefault = "_27-g";
export var headerMedium = "_27-h";
export var headerPadding = "_27-e";
export var iconAndTitle = "_27-f";
export var large = "_27-4";
export var largePadding = "_27-a";
export var medium = "_27-3";
export var mediumPadding = "_27-9";
export var modal = "_27-0";
export var modalContainer = "_27-b";
export var noPadding = "_27-7";
export var skinny = "_27-1";
export var small = "_27-2";
export var smallPadding = "_27-8";
export var subtitle = "_27-j";
export var title = "_27-i";