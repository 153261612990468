// extracted by mini-css-extract-plugin
export var addDetails = "_5c-l";
export var addEditButton = "_5c-b";
export var addressFields = "_5c-2";
export var animationContainer = "_5c-0";
export var closeButton = "_5c-p";
export var customerDetails = "_5c-x";
export var customerInformationWrapper = "_5c-6";
export var dropdowns = "_5c-4";
export var error = "_5c-k";
export var fields = "_5c-n";
export var flex = "_5c-m";
export var grow = "_5c-3";
export var icon = "_5c-d";
export var imageButton = "_5c-q";
export var imagesWrapper = "_5c-s";
export var itemDetails = "_5c-i";
export var itemSelectionHeader = "_5c-9";
export var label = "_5c-w";
export var lineItemImage = "_5c-j";
export var lineItemReturnedWrapper = "_5c-h";
export var lineItemWrapper = "_5c-g";
export var modalContent = "_5c-f";
export var modalFooter = "_5c-1";
export var multipleChoiceAnswer = "_5c-u";
export var multipleChoiceAnswersWrapper = "_5c-t";
export var orderButton = "_5c-z";
export var orderLabel = "_5c-10";
export var orderSelection = "_5c-y";
export var question = "_5c-v";
export var search = "_5c-e";
export var selectCheckbox = "_5c-a";
export var selectionWrapper = "_5c-8";
export var spinner = "_5c-c";
export var subheader = "_5c-5";
export var title = "_5c-7";
export var uploadedImage = "_5c-o";
export var uploadedImageWrapper = "_5c-r";