// extracted by mini-css-extract-plugin
export var bubble = "_47-0";
export var container = "_47-3";
export var contrastBorder = "_47-4";
export var greyOut = "_47-2";
export var initialsWrapper = "_47-1";
export var large = "_47-9";
export var medium = "_47-8";
export var mediumSmall = "_47-7";
export var micro = "_47-5";
export var small = "_47-6";
export var tiny = "_47-b";
export var xTiny = "_47-a";